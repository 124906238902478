import * as React from 'react';
import clsx from 'clsx';
import Typography from '@/components/Typography';

const Grid = ({ children }: { children: React.ReactNode }) => (
  <div className="mt-7.5 grid w-full grid-cols-11 justify-items-center lg:mt-10">
    <div className="col-start-2 col-end-11 grid w-full grid-cols-12 gap-y-2.5 lg:gap-y-3">
      {children}
    </div>
  </div>
);

Grid.Header = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div
    className={clsx(
      'col-span-12 rounded-xl bg-blue py-2.5 px-4 lg:mb-0.5',
      className
    )}
  >
    <Typography
      as="h2"
      variant="header-4"
      className="text-center text-white lg:text-left"
    >
      {children}
    </Typography>
  </div>
);

Grid.Group = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => (
  <div
    className={clsx(
      'col-span-12 grid grid-cols-12 gap-y-2.5 lg:gap-y-3 lg:gap-x-10',
      className
    )}
  >
    {children}
  </div>
);

type Item = {
  header: string;
  info: string | React.ReactNode;
};

Grid.Item = ({
  children,
  colSpan,
  hiddenInfo = false,
}: {
  children: Item;
  colSpan?: string;
  hiddenInfo?: boolean;
}) => (
  <div
    className={clsx(
      'flex flex-col items-center justify-center gap-y-1 sm:items-start sm:justify-start',
      colSpan || 'col-span-12 sm:col-span-6 lg:col-span-4 '
    )}
  >
    <Typography
      as="h3"
      variant="body-text-3"
      className="text-center text-blue md:text-left"
    >
      {children.header}
    </Typography>
    <Typography
      as="p"
      variant="body-text-2"
      className={clsx(hiddenInfo && 'select-none blur-sm')}
    >
      {children.info}
    </Typography>
    <div className="h-[1px] w-full bg-gray-divider" />
  </div>
);

export default Grid;
