import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';
import Typography from '@/components/Typography';
import Icons from '@/components/Icons';
import { Data } from '@/utils/types';

const Header = ({
  make,
  model,
  year,
}: Pick<Data, 'make' | 'model' | 'year'>) => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(
          relativePath: { eq: "sprawdz-kosztorys-hero.jpg" }
        ) {
          childImageSharp {
            gatsbyImageData(
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  );
  const image = getImage(placeholderImage);
  const bgImage = convertToBgImage(image);

  return (
    <BackgroundImage Tag="section" {...bgImage} preserveStackingContext>
      <div className="container flex flex-col items-center gap-y-2.5 px-4 py-7.5 text-white lg:gap-y-5.5 lg:pt-12.5 lg:pb-25">
        <Typography variant="header-2" as="h1" className="text-center">
          Wynik analizy kosztorysu {make} {model} {year} r.
        </Typography>
        <div className="flex w-fit items-center justify-center gap-x-2.5 rounded-xl bg-white p-2 lg:p-3">
          <Icons size="medium" type="error" />
          <Typography as="p" variant="header-4" className="text-red">
            Uwaga! Wykryto zaniżenia
          </Typography>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default Header;
