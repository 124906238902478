import clsx from 'clsx';
import WarningIcon from '@/assets/svg/Warning-icon.inline.svg';
import CheckIcon from '@/assets/svg/Check-icon.inline.svg';
import React from 'react';

interface Props {
  size: 'small' | 'medium' | 'large';
  type: 'success' | 'error';
}

const Icons = ({ size, type }: Props) => {
  const styles = {
    boxColor: {
      success: 'bg-green',
      error: 'bg-red',
    },
    small: {
      icon: 'w-3 lg:w-4',
      box: 'w-6 h-6 lg:w-7 lg:h-7',
    },
    medium: {
      icon: 'w-4 lg:w-6',
      box: 'w-8 h-8 lg:w-10 lg:h-10',
    },
    large: {
      icon: 'w-11',
      box: 'w-15 h-15',
    },
  };
  return (
    <div
      className={clsx(
        'flex items-center justify-center rounded-lg',
        styles[size].box,
        styles.boxColor[type]
      )}
    >
      {type === 'error' && <WarningIcon className={styles[size].icon} />}
      {type === 'success' && (
        <CheckIcon className={clsx(styles[size].icon, 'fill-white')} />
      )}
    </div>
  );
};

export default Icons;
