import React, { ComponentProps } from 'react';
import Layout from '@/components/Layout';
import Seo from '@/components/Seo';
import Header from '@/components/Header';
import Typography from '@/components/Typography';

import Audi from '@/assets/svg/Audi-logo.inline.svg';
import BMW from '@/assets/svg/BMW-logo.inline.svg';
import Ford from '@/assets/svg/Ford-logo.inline.svg';
import Opel from '@/assets/svg/Opel-logo.inline.svg';
import Volkswagen from '@/assets/svg/Volkswagen-logo.inline.svg';

import Icons from '@/components/Icons';
import { StaticImage } from 'gatsby-plugin-image';
import Grid from '@/components/Grid';
import Steps from '@/components/Steps';
import { Data } from '@/utils/types';

type Slug = 'audi' | 'bmw' | 'ford' | 'opel' | 'volkswagen';

const carLogos: Record<Slug, React.ReactNode> = {
  audi: <Audi className="mt-7.5" />,
  bmw: <BMW className="mt-7.5" />,
  ford: <Ford className="mt-7.5" />,
  opel: <Opel className="mt-7.5" />,
  volkswagen: <Volkswagen className="mt-7.5" />,
};

type ReportData = {
  report: {
    slug: Slug;
    percentage: string;
  } & Data;
};

const Report = ({ pageContext }: { pageContext: ReportData }) => {
  const estimateData = {
    slug: pageContext.report.slug,
    percentage: pageContext.report.percentage,
    model: pageContext.report.model,
    year: pageContext.report.year,
    make: pageContext.report.make,
    vin: pageContext.report.vin,
    odometer: pageContext.report.odometer,
    last_inspection: pageContext.report.last_inspection,
    first_registration: pageContext.report.first_registration,
    insurance_company: pageContext.report.insurance_company,
    damage_type: pageContext.report.damage_type,
    damage_number: pageContext.report.damage_number,
    compensation_amount: pageContext.report.compensation_amount,
    damage_date: pageContext.report.damage_date,
  };

  return (
    <Layout>
      <Header
        model={estimateData.model}
        year={estimateData.year}
        make={estimateData.make}
      />
      <section
        style={{ boxShadow: '0px 8px 40px rgba(32, 36, 41, 0.08)' }}
        className="relative mx-auto flex w-full max-w-[1014px] flex-col items-center justify-center border-t-8 border-red bg-white py-10 drop-shadow-default lg:-top-16 lg:rounded-lg lg:pt-15 lg:pb-17.5"
      >
        <Typography
          variant="header-3"
          as="h1"
          className="px-4 text-center lg:px-0"
        >
          Nasz system skutecznie odczytał{' '}
          <span className="text-red">{estimateData.percentage}</span> danych z
          kosztorysu
        </Typography>

        {carLogos[estimateData.slug]}

        <Grid>
          <Grid.Header>Informacje o pojeździe</Grid.Header>
          <Grid.Group>
            <Grid.Item>
              {{
                header: 'Marka i model',
                info: `${estimateData.make} ${estimateData.model}`,
              }}
            </Grid.Item>
            <Grid.Item>
              {{
                header: 'Rocznik',
                info: `${estimateData.year}`,
              }}
            </Grid.Item>
            <Grid.Item hiddenInfo>
              {{
                header: 'VIN',
                info: `${estimateData.vin}`,
              }}
            </Grid.Item>
            <Grid.Item>
              {{
                header: 'Stan licznika',
                info: `${Number(estimateData.odometer).toLocaleString(
                  'pl'
                )} km`,
              }}
            </Grid.Item>
            <Grid.Item>
              {{
                header: 'Termin ostatniego przeglądu',
                info: `${estimateData.last_inspection}`,
              }}
            </Grid.Item>

            <Grid.Item>
              {{
                header: 'Data pierwszej rejestracji',
                info: `${estimateData.first_registration}`,
              }}
            </Grid.Item>
          </Grid.Group>
          <Grid.Header className="mt-5 lg:mt-4">
            Informacje o odszkodowaniu
          </Grid.Header>
          <Grid.Group>
            <Grid.Item>
              {{
                header: 'Towarzystwo ubezpieczeniowe',
                info: `${estimateData.insurance_company}`,
              }}
            </Grid.Item>
            <Grid.Item>
              {{
                header: 'Typ szkody',
                info: `${estimateData.damage_type}`,
              }}
            </Grid.Item>
            <Grid.Item hiddenInfo>
              {{
                header: 'Nr szkody',
                info: `${estimateData.damage_number}`,
              }}
            </Grid.Item>
            <Grid.Item>
              {{
                header: 'Suma odszkodowania',
                info: `${Number(
                  estimateData.compensation_amount
                ).toLocaleString('pl', {
                  style: 'currency',
                  currency: 'PLN',
                })}`,
              }}
            </Grid.Item>
            <Grid.Item>
              {{
                header: 'Data szkody',
                info: `${estimateData.damage_date}`,
              }}
            </Grid.Item>
          </Grid.Group>
          <Grid.Header className="mt-5 lg:mt-4">
            Informacje o zaniżeniu
          </Grid.Header>
          <Grid.Group className="lg:place-items-start">
            <div className="col-span-12 grid grid-cols-12 gap-y-2.5 sm:col-span-7 lg:col-span-7 lg:gap-y-4">
              <Grid.Item colSpan="col-span-12 h-fit">
                {{
                  header: 'Zaniżenia na materiałach lakierniczych',
                  info: (
                    <span className="my-1 flex items-center gap-x-2.5">
                      <Icons size="small" type="error" />
                      <Typography
                        as="span"
                        variant="body-text-3"
                        className="text-red"
                      >
                        Wykryto zaniżenie
                      </Typography>
                    </span>
                  ),
                }}
              </Grid.Item>
              <Grid.Item colSpan="col-span-12 h-fit">
                {{
                  header: 'Zaniżenia na roboczogodzinach lakierniczych',
                  info: (
                    <span className="my-1 flex items-center gap-x-2.5">
                      <Icons size="small" type="error" />
                      <Typography
                        as="span"
                        variant="body-text-3"
                        className="text-red"
                      >
                        Wykryto zaniżenie
                      </Typography>
                    </span>
                  ),
                }}
              </Grid.Item>
              <Grid.Item colSpan="col-span-12 h-fit">
                {{
                  header: 'Zaniżenia na roboczogodzinach blacharskich',
                  info: (
                    <span className="my-1 flex items-center gap-x-2.5">
                      <Icons size="small" type="error" />
                      <Typography
                        as="span"
                        variant="body-text-3"
                        className="text-red"
                      >
                        Wykryto zaniżenie
                      </Typography>
                    </span>
                  ),
                }}
              </Grid.Item>
              <Grid.Item colSpan="col-span-12 h-fit">
                {{
                  header: 'Części nieoryginalne',
                  info: (
                    <span className="my-1 flex items-center gap-x-2.5">
                      <Icons size="small" type="error" />
                      <Typography
                        as="span"
                        variant="body-text-3"
                        className="text-red"
                      >
                        Wykryto zaniżenie
                      </Typography>
                    </span>
                  ),
                }}
              </Grid.Item>
              <Grid.Item colSpan="col-span-12 h-fit">
                {{
                  header: 'Niesłusznie udzielone rabaty',
                  info: (
                    <span className="my-1 flex items-center gap-x-2.5">
                      <Icons size="small" type="success" />
                      <Typography
                        as="span"
                        variant="body-text-3"
                        className="text-green"
                      >
                        Brak zaniżeń
                      </Typography>
                    </span>
                  ),
                }}
              </Grid.Item>
            </div>
            <div className="col-span-12 place-self-center sm:col-span-5 lg:col-span-5">
              <StaticImage
                className="w-[210px] md:min-h-[394px] lg:w-full"
                src="../assets/images/wykryto-zanizenia-kosztorys.png"
                alt="docs"
              />
            </div>
          </Grid.Group>
        </Grid>
      </section>
      <Steps />
    </Layout>
  );
};
export const Head = () => {
  return (
    <Seo
      title="Analiza Twojego kosztorysu naprawy pojazdu - sprawdz-kosztorys.pl"
      description="Sprawdź szczegółowe wyniki analizy kosztorys naprawy pojazdu. Zobacz, o ile
      Twoje odszkodowanie z OC sprawcy jest zaniżone!"
    />
  );
};

export default Report;
